define("discourse/plugins/discourse-rss-polling/discourse/routes/admin-plugins-rss-polling", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-rss-polling/admin/models/rss-polling-feed-settings"], function (_exports, _discourse, _rssPollingFeedSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _rssPollingFeedSettings.default.show().then(result => result.feed_settings);
    },
    setupController(controller, model) {
      controller.setProperties({
        model
      });
    }
  });
});