define("discourse/plugins/discourse-rss-polling/admin/models/rss-polling-feed-settings", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    show() {
      return (0, _ajax.ajax)("/admin/plugins/rss_polling/feed_settings.json");
    },
    update(feedSettings) {
      return (0, _ajax.ajax)("/admin/plugins/rss_polling/feed_settings", {
        type: "PUT",
        contentType: "application/json",
        processData: false,
        data: JSON.stringify({
          feed_settings: feedSettings
        })
      });
    }
  };
});